import React from "react";
import { Parallax } from "react-parallax";
import Reveal from "react-reveal";
import Slider from "react-slick";
import { graphql } from "gatsby";   
import Layout from "../components/layouts";

const Partner = ({ data }) => {    
    const { prismic } = data;
    const body = prismic?.allPartner_pages?.edges[0]?.node?.body;
  console.log(body);
    const scrollToSignup = () => {
      window.scrollTo(0, document.getElementById("form").offsetTop - 100);
    };
    const bgImage = body.find(x => x.type === 'header_image')?.primary?.header_image?.url;
    const introDesc = body.find(x => x.type === 'intro_description')?.primary?.intro_description;
    const cta = body.find(x => x.type === 'cta_button')?.primary?.cta_text;
    const partnerLogos = body.find(x => x.type === 'partner_logos')?.fields;
    const capabilities = body.find(x => x.type === 'capabilities')?.fields;
    const highlightVideo = body.find(x => x.type === 'highlight_video')?.primary?.youtube_id;
    const quote = body.find(x => x.type === 'quote')?.primary?.quote_text;
    const form = body.find(x => x.type === 'mailchimp_form')?.primary;
    const gallery = body.find(x => x.type === 'gallery')?.fields;
    const gallerySettings = {
      dots: true,
      infinite: true,
      arrows: false
  }
    return (
      <Layout navBg>
        <div className="partner-page">
          <Parallax className="page-header" bgImage={bgImage || ''} strength={400}>
              <div className="page-header-inner">
                  <div className="partner-intro-container">
                      <h1 className="page-header-title">Partner With&nbsp;Us</h1>
                      {introDesc && (
                        <div className="partner-intro">
                          <p>{introDesc}</p>
                        </div>
                      )}
                      {cta && (
                        <div className="partner-cta" onClick={scrollToSignup}>
                          <div className="btn primary small solid">
                              <span>{cta}</span>
                          </div>
                        </div>
                      )}
                  </div>
                  {partnerLogos && partnerLogos.length && (
                    <div className="partner-logos-container">
                      <div className="partner-logos-title">Our Partners:</div>
                      <div className="partner-logos">
                        {partnerLogos.map(logo => (
                          <div key={logo.partner_logo.url} className="partner-logo" style={{backgroundImage: `url(${logo.partner_logo.url})`}}></div>
                        ))}
                      </div>
                  </div>
                  )}
                  
              </div>
          </Parallax>
          <div className="partner-body">
              {capabilities && capabilities.length && (
                <div className="partner-intro">

                  <h1>Capabilities and Specializations</h1>
                  <div className="partner-capabilities">
                  {capabilities.map(cap => {
                    const { caption, icon } = cap;
                    console.log(icon);
                    return (
                      <Reveal key={caption} effect="animated zoomIn">
                        <div className="partner-capability">
                        <div className="partner-capability-icon" style={{backgroundImage: `url(${icon.url})`}}></div>
                        <div className="partner-capability-text">
                            <p>{caption}</p>
                        </div>
                        </div>
                      </Reveal>
                    )
                  })}
                    </div>
                </div>
              )}

              <div className="partner-media-container">
                  {highlightVideo && (
                    <Reveal effect="animated fadeInLeft">
                      <div className="partner-media-video">
                      <div className="embed-container">
                            <iframe src={`https://www.youtube.com/embed/${highlightVideo}`} frameBorder='0' allowFullScreen></iframe>
                        </div>
                      </div>
                    </Reveal>
                  )}
                  
                  {quote && (
                    <Reveal effect="animated fadeInRight">
                      <div className="partner-quote-container">
                      <div className="partner-quote">
                            <h1>
                              {quote}
                            </h1>
                        </div>
                        {/* <div className="partner-credit">
                            <p><strong>Harvey Goldsmith</strong></p>
                            <p>Cofounder, Live Aid</p>
                        </div> */}
                      </div>
                    </Reveal>
                  )}
              </div>

              <div className="partner-contact-container" id="form">
                {form && (
                  <Reveal effect="animated fadeInLeft">
                      <div className="form-container">
                        <div className="form-inner application-form-inner">
                            <h1>{form.form_title}</h1>
                            <div dangerouslySetInnerHTML={{ __html: form.html_embed }} />
                        </div>
                      </div>
                      
                  </Reveal>
                )}
                {gallery && gallery.length && (
                  <Reveal effect="animated fadeInRight">
                    <div className="partner-gallery-container">
                    <Slider {...gallerySettings}>
                      {gallery.map(gall => (
                        <div key={gall.gallery_image.url} className="partner-image"><img src={gall.gallery_image.url} /></div>
                      ))}
                      </Slider>
                    </div>
                      
                  </Reveal>
                )}
                
              </div>

          </div>
      </div>
    </Layout>
  );
}

export default Partner;

export const query = graphql`
{
  prismic {
    allPartner_pages {
      edges {
        node {
          body {
            ... on PRISMIC_Partner_pageBodyHeader_image {
              type
              label
              primary {
                header_image
              }
            }
            ... on PRISMIC_Partner_pageBodyIntro_description {
              type
              label
              primary {
                intro_description
              }
            }
            ... on PRISMIC_Partner_pageBodyCta_button {
              type
              label
              primary {
                cta_text
                link {
                  ... on PRISMIC_Partner_page {
                    _linkType
                    _meta {
                      uid
                    }
                  }
                }
              }
            }
            ... on PRISMIC_Partner_pageBodyPartner_logos {
              type
              label
              fields {
                partner_logo
              }
            }
            ... on PRISMIC_Partner_pageBodyCapabilities {
              type
              label
              fields {
                caption
                icon
              }
            }
            ... on PRISMIC_Partner_pageBodyHighlight_video {
              type
              label
              primary {
                youtube_id
              }
            }
            ... on PRISMIC_Partner_pageBodyQuote {
              type
              label
              primary {
                quote_text
              }
            }
            ... on PRISMIC_Partner_pageBodyGallery {
              type
              label
              fields {
                gallery_image
              }
            }
            ... on PRISMIC_Partner_pageBodyMailchimp_form {
              type
              label
              primary {
                form_title
                html_embed
              }
            }
          }
        }
      }
    }
  }
}
`